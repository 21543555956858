<template>
    <v-card
        elevation="0"
        :height="900"
        class="my-12 pr-6 mr-6 overflow-y-auto"
        :loading="loading"
        id="book-list-library"
    >
        <v-row v-show="matrix.length > 0" align="center" justify="space-between">
            <v-col cols="12" class="py-0 px-6" v-for="(row, h) in matrix" :key="'row-' + h">
                <v-row align="center" justify="start">
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                        xl="2"
                        v-for="(book, k) in row"
                        :key="'bookP-' + k"
                    >
                        <Book :book="book" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row justify="center" style="padding-top: 50px" v-show="!loading && matrix.length < 1">
            <h2 class="text-h4 font-weight-black px-2">
                {{ $t('library.no_results') }}
            </h2>
        </v-row>
        <v-row
            justify="center"
            v-show="books && books.length < totalElements"
            style="height: 100px; margin-bottom: 20px"
        >
            <v-progress-circular indeterminate color="primary" size="100" :width="10" />
        </v-row>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Book from './Book.vue'
import BookService from '../../services/book'

export default {
    props: {
        age: { type: Array, default: null },
        genres: { type: Array, default: null },
        resourceType: { type: String, default: null },
        search: { type: String, default: '' },
        topics: { type: Array, default: null },
        countries: { type: Array, default: null },
        lexiles: { type: Array, default: null },
    },
    components: {
        Book,
    },
    data() {
        return {
            books: [],
            currentPage: 0,
            loading: false,
            totalElements: 0,
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.userData.user,
        }),
        matrix() {
            if (this.books.length === 0) {
                return this.listToMatrix([], 1)
            }
            let result = this.listToMatrix(this.books, 6)
            if (this.$vuetify.breakpoint.xs) {
                result = this.listToMatrix(this.books, 1)
            }
            if (this.$vuetify.breakpoint.sm) {
                result = this.listToMatrix(this.books, 2)
            }
            if (this.$vuetify.breakpoint.md) {
                result = this.listToMatrix(this.books, 3)
            }
            if (this.$vuetify.breakpoint.lg) {
                result = this.listToMatrix(this.books, 4)
            }
            return result
        },
    },
    mounted() {
        this.loadData()
        const vm = this
        setTimeout(() => {
            const content = document.querySelector('#book-list-library')
            if (content) {
                content.addEventListener('scroll', () => {
                    if (vm.totalElements === vm.books.length) return
                    if (vm.loading) return
                    const tempScrollTop = content.scrollTop
                    const totalHeight = tempScrollTop + content.offsetHeight
                    if (content.scrollHeight <= (totalHeight + 100)) {
                        // Increase page to request more books
                        vm.currentPage += 1
                        vm.getMoreUserPayBooks()
                        content.scrollTop = tempScrollTop
                    }
                })
            }
        }, 1000)
    },
    methods: {
        getMoreUserPayBooks() {
            this.loading = true
            const params = {
                bookUserId: this.user.id,
                categoryIdList: this.topics && this.topics.length > 0 ? this.topics : null,
                resourceType: this.resourceType,
                textTypeIdList: this.genres && this.genres.length > 0 ? this.genres : null,
                searchQuery: this.search,
                ageList: this.age && this.age.length > 0 ? this.age : null,
                countryIdList: this.countries && this.countries.length > 0 ? this.countries : null,
                lexileStart: this.lexiles && this.lexiles.length > 0 ? this.lexiles[0] : null,
                lexileEnd: this.lexiles && this.lexiles.length > 0 ? this.lexiles[1] : null
            }
            return BookService.getBookPageByTitleAndParams(
                params,
                30,
                this.currentPage,
            )
            .then((response) => {
                this.books.push(...response.content.content)
                this.totalElements = response.content.totalElements
            })
            .catch((error) => console.error(error))
            .finally(() => this.loading = false)
        },
        getUserPayBooks() {
            this.loading = true
            this.books = []
            const params = {
                bookUserId: this.user.id,
                categoryIdList: this.topics && this.topics.length > 0 ? this.topics : null,
                resourceType: this.resourceType,
                textTypeIdList: this.genres && this.genres.length > 0 ? this.genres : null,
                searchQuery: this.search,
                ageList: this.age && this.age.length > 0 ? this.age : null,
                countryIdList: this.countries && this.countries.length > 0 ? this.countries : null,
                lexileStart: this.lexiles && this.lexiles.length > 0 ? this.lexiles[0] : null,
                lexileEnd: this.lexiles && this.lexiles.length > 0 ? this.lexiles[1] : null
            }
            return BookService.getBookPageByTitleAndParams(
                params,
                30,
                this.currentPage,
            )
            .then((response) => {
                this.books = response.content.content
                this.totalElements = response.content.totalElements
            })
            .catch((error) => console.error(error))
            .finally(() => this.loading = false)
        },
        listToMatrix(list, elementsPerSubArray) {
            const matrix = []
            let i
            let k

            for (i = 0, k = -1; i < list.length; i += 1) {
                if (i % elementsPerSubArray === 0) {
                    k += 1
                    matrix[k] = []
                }
                matrix[k].push(list[i])
            }
            return matrix
        },
        loadData() {
            this.currentPage = 0
            return this.getUserPayBooks()
        },
    },
    watch: {
        age() {
            this.loadData()
        },
        genres() {
            this.loadData()
        },
        resourceType() {
            this.loadData()
        },
        search() {
            this.loadData()
        },
        topics() {
            this.loadData()
        },
        countries() {
            this.loadData()
        },
        lexiles() {
            this.loadData()
        }
    },
}
</script>
<style scoped></style>
