const LEXILE_ITEMS = [
  {
      id: 1,
      name: '280 - 530L',
      value: [280, 530]
  },
  {
      id: 2,
      name: '320 - 650L',
      value: [320, 650]
  },
  {
      id: 3,
      name: '420 - 820L',
      value: [420, 820]
  },
  {
      id: 4,
      name: '540 - 940L',
      value: [540, 940]
  },
  {
      id: 5,
      name: '600 - 1010L',
      value: [600, 1010]
  },
  {
      id: 6,
      name: '640 - 1070L',
      value: [640, 1070]
  },
  {
      id: 7,
      name: '680 - 1120L',
      value: [680, 1120]
  },
  {
      id: 8,
      name: '720 - 1185L',
      value: [720, 1185]
  },
  {
      id: 9,
      name: '760 - 1260L',
      value: [760, 1260]
  },
  {
      id: 10,
      name: '790 - 1335L',
      value: [790, 1335]
  },
  {
      id: 11,
      name: '850 - 1335L',
      value: [850, 1335]
  },
  {
      id: 12,
      name: '955+',
      value: [955, 20000]
  },
]

export { LEXILE_ITEMS }
