import Vue from 'vue'
import * as Sentry from '@sentry/vue'

import { mapState } from 'vuex'

import analyticEvents from '@/services/analyticEvents'

const AnalyticEventsMixin = Vue.extend({
  computed: {
    ...mapState({
      userId: (state: any) => state.userData.user.id,
      schoolId: (state: any) => state.userData.user?.school?.id
    }),
  },
  methods: {
    async createAnalyticEvent(eventType: Number, eventMetadata: Object = {}) {
      try {
        const payload = {
          user_id: this.userId,
          school_id: this.schoolId,
          course_id: null,
          event_type: eventType,
          metadata: eventMetadata
        }

        await analyticEvents.createEvent(payload)
      } catch (error) {
        Sentry.captureException(error)
      }
    }
  }
})

export default AnalyticEventsMixin
