<template>
    <div :class="classBook">
        <v-card
            data-cy="item-carousel"
            height="200px"
            width="150px"
            class="book"
            color="white"
            elevation="3"
            @click="setCardDescription"
        >
            <v-img
                :src="`https://assets.beereaders.com/lbr/image/${imageSource}/cover_small.jpg?v=${new Date().getTime()}`"
                height="200px"
                width="150px"
            />
        </v-card>
        <v-expand-x-transition>
            <v-card
                color="white"
                height="250px"
                width="400px"
                class="content-behind"
                elevation="3"
                v-show="cardDescription"
            >
                <v-container>
                    <v-row justify="end">
                        <v-col cols="7" style="margin-right: 20px">
                            <v-row style="height: 90px">
                                <v-col cols="12">
                                    <v-row>
                                        <h4 class="title-text">{{ title }}</h4>
                                    </v-row>
                                    <v-row>
                                        <star-rating
                                            :star-size="20"
                                            :show-rating="false"
                                            rounded-corners
                                            v-model="rating"
                                            read-only
                                        />
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row>
                                <p class="text-description pt-1">{{ description }}</p>
                            </v-row>
                            <v-row justify="start">
                                <v-btn
                                    data-cy="read-book-button"
                                    rounded
                                    large
                                    color="#FF6F3A"
                                    class="no-uppercase"
                                    dark
                                    width="200px"
                                    @click="openBook(book_id)"
                                >
                                    {{ $t('library.title_button_card') }}
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-expand-x-transition>
    </div>
</template>

<script>
import AnalyticEventsMixin from '@/mixins/analyticEvents' //eslint-disable-line
import { AnalyticEventTypes } from '../../constants/AnalyticEventTypes'

export default {
    mixins: [AnalyticEventsMixin],
    data() {
        return {
            cardDescription: false,
        }
    },
    props: ['title', 'rating', 'description', 'imageSource', 'book_id'],
    computed: {
        classBook() {
            if (!this.cardDescription) {
                return 'book-content'
            }
            return 'book-content-active'
        },
    },
    methods: {
        setCardDescription() {
            this.cardDescription = !this.cardDescription
            this.$emit('cardPopUp', this.cardDescription)
        },
        openBook(bookId) {
            this.$router.push(`book/${bookId}`)
            this.createAnalyticEvent(AnalyticEventTypes.GoToBookDetail)
        }
    },
}
</script>

<style scoped>
.book-content-active {
    height: 300px;
    width: 450px;
    position: relative;
    align-content: center;
    justify-content: center;
}

.book-content {
    height: 300px;
    width: 190px;
    position: relative;
    align-content: center;
    justify-content: center;
}

.book {
    position: absolute;
    top: 50px;
    z-index: 1;
}

.content-behind {
    position: absolute;
    top: 25px;
    left: 25px;
}

.text-description {
    font-size: 13px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.no-uppercase {
    text-transform: none;
}

.title-text {
    margin-top: -12px;
    font-size: 20px;
    font-weight: bold;
    color: rgba(60, 0, 0, 0.8);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
