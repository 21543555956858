var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.book.id)?_c('v-card',{attrs:{"elevation":"0","height":"260"}},[_c('v-card',{attrs:{"elevation":"0"},on:{"click":_vm.goToBook}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-img',{staticClass:"book-img border-radius-2 transition-all mx-2",attrs:{"src":`https://assets.beereaders.com/lbr/image/${_vm.book.resourceDir}/cover_small.jpg?v=${new Date().getTime()}`,"height":this.$vuetify.breakpoint.xs ||
                    this.$vuetify.breakpoint.sm ||
                    this.$vuetify.breakpoint.md
                        ? '40vh'
                        : '25vh',"alt":"Av"},on:{"load":_vm.handleLoaded}}),(!_vm.loaded)?_c('v-skeleton-loader',{staticClass:"position-absolute opacity-none",attrs:{"height":this.$vuetify.breakpoint.xs ||
                    this.$vuetify.breakpoint.sm ||
                    this.$vuetify.breakpoint.md
                        ? '40vh'
                        : '25vh',"width":"120","type":"image","transition":'scale-transition'}}):_vm._e()],1),_c('v-row',{staticClass:"mt-2 mb-0 mx-4",attrs:{"justify":"center"}},[_c('p',{staticClass:"text-center mb-2 truncate-overflow"},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.book.title)+" ")])]}}],null,false,3529404853)},[_c('span',[_vm._v(_vm._s(_vm.book.title))])])],1)]),_c('v-row',{staticClass:"my-0 mx-4 py-0",attrs:{"justify":"center"}},[_c('p',{staticClass:"text-center text-subtitle-2 author-text my-0 py-0"},[_vm._v(" "+_vm._s(_vm.book.author)+" ")])])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }