<template>
    <v-row data-cy="carousel-box">
        <v-col cols="1" align-self="center">
            <v-row justify="end" v-if="listOfBooksAvailable === 1">
                <v-btn @click="showLeft" :disabled="disableLeft" icon>
                    <v-icon color="#FF6F3A" size="50px" style="left: 15%; z-index: 1">
                        mdi-arrow-left-circle
                    </v-icon>
                </v-btn>
            </v-row>
        </v-col>
        <v-col cols="10" style="text-align: left">
            <h2>{{ titleCarousel }}</h2>
            <div
                style="margin-top: -30px"
                v-if="listOfBooksAvailable === 1"
                class="position-relative"
            >
                <VueSlickCarousel v-bind="settings" ref="carousel" @beforeChange="before">
                    <div :class="`${classCard}`" v-for="(item, i) in booksList.data" :key="i">
                        <book-card-pop-up
                            @cardPopUp="cardPopUp = $event"
                            :title="item.title"
                            :rating="item.rating"
                            :description="item.bookAbstract"
                            :imageSource="item.resourceDir"
                            :book_id="item.id"
                        />
                    </div>
                </VueSlickCarousel>
            </div>
            <v-row class="d-flex flex-row justify-center" v-if="listOfBooksAvailable === 0">
                <v-col cols="2">
                    <v-skeleton-loader height="300px" width="100%" type="image" />
                </v-col>
                <v-col cols="2">
                    <v-skeleton-loader height="300px" width="100%" type="image" />
                </v-col>
                <v-col cols="2">
                    <v-skeleton-loader height="300px" width="100%" type="image" />
                </v-col>
                <v-col cols="2">
                    <v-skeleton-loader height="300px" width="100%" type="image" />
                </v-col>
                <v-col cols="2">
                    <v-skeleton-loader height="300px" width="100%" type="image" />
                </v-col>
                <v-col cols="2">
                    <v-skeleton-loader height="300px" width="100%" type="image" />
                </v-col>
            </v-row>

            <v-card height="200px" width="100%" color="#FFFF7A" v-if="listOfBooksAvailable > 1">
                <v-overlay absolute value="true" opacity="0.6">
                    <v-row
                        class="ma-0 pa-12 text-h6 text-md-h4 font-weight-regular"
                        justify="center"
                        align="center"
                    >
                        {{ textDisabled }}
                    </v-row>
                </v-overlay>
            </v-card>
        </v-col>
        <v-col cols="1" align-self="center">
            <v-row justify="start" v-if="listOfBooksAvailable === 1">
                <v-btn icon color="secondary" :disabled="disableRigth" @click="showRight">
                    <v-icon color="#FF6F3A" size="50px" style="right: 15%; z-index: 1">
                        mdi-arrow-right-circle
                    </v-icon>
                </v-btn>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import BookCardPopUp from './BookCardPopUp.vue'

// eslint-disable-next-line import/no-unresolved
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    components: {
        'book-card-pop-up': BookCardPopUp,
        VueSlickCarousel,
    },
    data() {
        return {
            settings: {
                infinite: false,
                arrows: false,
                slidesToScroll: 1,
                variableWidth: true,
                speed: 500,
                rows: 1,
            },
            disableLeft: true,
            disableRigth: false,
        }
    },
    props: ['titleCarousel', 'booksList'],
    computed: {
        classCard() {
            if (this.cardPopUp) {
                return 'pop-up-enabled'
            }
            return 'pop-up-disabled'
        },
        listOfBooksAvailable() {
            return this.booksList.code
        },
        textDisabled() {
            if (this.booksList.code === 2) {
                return this.$t('library.no_books_found')
            }
            return this.$t('library.error_consulting_books')
        },
    },
    methods: {
        showRight() {
            this.$refs.carousel.next()
        },
        showLeft() {
            this.$refs.carousel.prev()
        },
        before(oldSlideIndex, newSlideIndex) {
            if (newSlideIndex === this.booksList.data.length - 1) {
                this.disableRigth = true
                this.disableLeft = false
            } else if (newSlideIndex > 0) {
                this.disableRigth = false
                this.disableLeft = false
            } else {
                this.disableRigth = false
                this.disableLeft = true
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.gradient-background {
    width: 20%;
    position: absolute;
    bottom: 0;
    height: 85%;
}
.gradient-background-carrousel-right {
    right: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 1) 100%);
}

.gradient-background-carrousel-left {
    left: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 30%);
}

.pop-up-disabled,
.pop-up-enabled {
    outline: 0;
}
</style>
