import Services from './base-services'

const service = new Services('metaDataCategory')

export default {
    getAll() {
        return service.get('getAll', { params: { showMetaDataCategoryWithBooks: true } })
    },
    getAllTeacherCategory() {
        return service.get('getAllTeacherCategory')
    },
}
