<template>
    <v-row>
        <v-col cols="12" sm="8">
            <v-row justify="start">
                {{ $t('library.filters') }}
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-chip
                        v-for="(filter, i) in filterSelection"
                        :key="`filters-${i}`"
                        :input-value="filter"
                        close
                        @click:close="removeOfSomeFilter(filter)"
                    >
                        {{ filter.name }}
                    </v-chip>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12">
            <v-row>
                <v-col cols="10" md="8">
                    <v-text-field v-model="search" :label="$t('library.search')" outlined rounded />
                </v-col>
                <v-col cols="1">
                    <v-btn
                        class="search-icon px-8 rounded-pill"
                        color="primary"
                        x-large
                        @click="toSearch"
                    >
                        <v-icon color="white"> fas fa-search </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row justify="start">
                {{ $t('library.age') }}
            </v-row>
            <v-row>
                <v-col cols="6">
                    <v-checkbox
                        v-for="(age, i) in ages"
                        :key="`age-${i}`"
                        :label="age.name"
                        :value="age.id"
                        @click="updateAges(age)"
                        hide-details
                        class="mt-0"
                    />
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" sm="8">
            <v-row justify="start">
                {{ $t('library.topic') }}
            </v-row>
            <v-row>
                <v-col>
                    <v-autocomplete
                        v-model="selectionTopics"
                        :items="topicItems"
                        attach
                        chips
                        item-text="name"
                        item-value="id"
                        :label="$t('library.select_topic')"
                        outlined
                        hide-details
                        :dense="!selectionTopics.length > 0"
                        multiple
                    >
                        <template v-slot:selection="data">
                            <v-chip
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                @click:close="removeTopic(data.item)"
                            >
                                {{ data.item.name }}
                            </v-chip>
                        </template>
                        <template v-slot:item="data">
                            <span>{{ data.item.name }}</span>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                {{ $t('library.genre') }}
            </v-row>
            <v-row justify="start">
                <v-col>
                    <v-autocomplete
                        v-model="selectionGenres"
                        :items="genreItems"
                        attach
                        chips
                        item-text="name"
                        item-value="id"
                        :dense="!selectionGenres.length > 0"
                        hide-details
                        :label="$t('library.select_genre')"
                        outlined
                        multiple
                    >
                        <template v-slot:selection="data">
                            <v-chip
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                @click:close="removeGenre(data.item)"
                            >
                                {{ data.item.name }}
                            </v-chip>
                        </template>
                        <template v-slot:item="data">
                            <span>{{ data.item.name }}</span>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                {{ $t('library.country') }}
            </v-row>
            <v-row justify="start">
                <v-col>
                    <v-autocomplete
                        v-model="countrySelected"
                        :items="countries"
                        attach
                        chips
                        item-text="name"
                        item-value="id"
                        :dense="!countrySelected.length > 0"
                        hide-details
                        :label="$t('library.select_country')"
                        outlined
                        multiple
                    >
                        <template v-slot:selection="data">
                            <v-chip
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                @click:close="removeCountry(data.item)"
                            >
                                {{ data.item.name }}
                            </v-chip>
                        </template>
                        <template v-slot:item="data">
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                {{ $t('library.lexile') }}
            </v-row>
            <v-row justify="start">
                <v-col>
                    <v-autocomplete
                        v-model="lexilesSelected"
                        :items="lexileItems"
                        attach
                        item-text="name"
                        item-value="value"
                        hide-details
                        :label="$t('library.select_lexile')"
                        outlined
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
        </v-col>
        <!-- Pending refactoring -->
        <!-- <v-col cols="7" sm="9" >
            <v-row>
                {{ $t('library.price') }}
            </v-row>
            <v-row>
                <v-radio-group v-model="resourceTypeRadioGroup">
                    <v-radio
                        v-for="(type, i) in resourceType"
                        :key="`resource-${i}`"
                        :label="type.label"
                        :value="type.value"
                    />
                </v-radio-group>
            </v-row>
        </v-col> -->
    </v-row>
</template>

<script>
import * as Sentry from '@sentry/vue'
import MetaDataCategoryService from '../../services/metaDataCategory'
import TextTypeService from '../../services/textType'
import BaseCountryService from '../../services/baseCountry'
import { LEXILE_ITEMS } from '../../data/lexileRanges'

export default {
    data() {
        return {
            countrySelected: [],
            ages: [
                {
                    id: 1,
                    name: this.$t('library.age_1'),
                    value: [6, 7, 8],
                },
                {
                    id: 2,
                    name: this.$t('library.age_2'),
                    value: [9, 10, 11],
                },
                {
                    id: 3,
                    name: this.$t('library.age_3'),
                    value: [12, 13],
                },
                {
                    id: 4,
                    name: this.$t('library.age_4'),
                    value: [14, 15],
                },
                {
                    id: 5,
                    name: this.$t('library.age_5'),
                    value: [16, 17, 18],
                },
            ],
            countries: [],
            checkboxAges: [],
            selectionGenres: [],
            selectionTopics: [],
            resourceTypeRadioGroup: '',
            search: '',
            topicItems: [
                {
                    id: 0,
                    name: 'Cargando...',
                    value: 0,
                },
            ],
            genreItems: [
                {
                    id: 0,
                    name: 'Cargando...',
                    value: 0,
                },
            ],
            lexilesSelected: [null, null],
        }
    },
    mounted() {
        this.getGenders()
        this.getTopics()
        this.getCountries()
    },
    computed: {
        resourceType() {
            return [
                { label: this.$t('library.free'), value: 'FREE' },
                { label: this.$t('library.with_price'), value: 'PAY' },
            ]
        },
        genresFilterList() {
            return this.genreItems.filter((genre) =>
                this.selectionGenres.find((selected) => selected === genre.id)
            )
        },
        topicsFilterList() {
            return this.topicItems.filter((topic) =>
                this.selectionTopics.find((selected) => selected === topic.id)
            )
        },
        countryFilterList() {
            return this.countries.filter((country) =>
                this.countrySelected.find((selected) => selected === country.id)
            )
        },
        filterSelection() {
            return [...this.genresFilterList, ...this.topicsFilterList, ...this.countryFilterList]
        },
        lexileItems() {
            return LEXILE_ITEMS
        },
    },
    methods: {
        getGenders() {
            TextTypeService.getAll()
                .then((response) => {
                    this.genreItems = response.content
                        .filter((textType) => textType.value !== 'REV') // Teacher doesn't need to see the 'REV (Revistas)'
                        .map((genre) => ({
                            id: genre.id,
                            name: this.$t(`textType.${genre.value}`),
                            value: false,
                        }))
                })
                .catch((error) => Sentry.captureException(error))
        },
        getTopics() {
            MetaDataCategoryService.getAllTeacherCategory()
                .then((response) => {
                    this.topicItems = response.content.map((topic) => ({
                        id: topic.id,
                        name: this.$t(`categories.${topic.value}`),
                        value: false,
                    }))
                })
                .catch((error) => Sentry.captureException(error))
        },
        getCountries() {
            BaseCountryService.getAll()
                .then((response) => {
                    this.countries = response.content.map((country) => ({
                        id: country.id,
                        name: country.name,
                        value: false,
                    }))
                })
                .catch((error) => Sentry.captureException(error))
        },
        toSearch() {
            this.$emit('search', this.search)
        },
        updateAges(age) {
            const initialAges = [...this.checkboxAges]
            // eslint-disable-next-line no-restricted-syntax
            for (const ageIterator of age.value) {
                if (initialAges.includes(ageIterator)) {
                    this.checkboxAges = this.checkboxAges.filter(
                        (element) => element !== ageIterator
                    )
                } else {
                    this.checkboxAges = [...this.checkboxAges, ageIterator]
                }
            }
            this.$emit('newAge', this.checkboxAges)
        },
        removeCountry(item) {
            const index = this.countrySelected.indexOf(item.id)
            if (index >= 0) this.countrySelected.splice(index, 1)
        },
        removeTopic(item) {
            const index = this.selectionTopics.indexOf(item.id)
            if (index >= 0) this.selectionTopics.splice(index, 1)
        },
        removeGenre(item) {
            const index = this.selectionGenres.indexOf(item.id)
            if (index >= 0) this.selectionGenres.splice(index, 1)
        },
        removeOfSomeFilter(item) {
            const genreItem = this.genresFilterList.find((genre) => genre.name === item.name)
            if (genreItem) this.removeGenre(genreItem)

            const topicItem = this.topicsFilterList.find((topic) => topic.name === item.name)
            if (topicItem) this.removeTopic(topicItem)

            const countryItem = this.countryFilterList.find((country) => country.name === item.name)
            if (countryItem) this.removeCountry(countryItem)
        },
    },
    watch: {
        resourceTypeRadioGroup() {
            this.$emit('newResourceType', this.resourceTypeRadioGroup)
        },
        search() {
            if (this.search === '') {
                this.$emit('search', this.search)
            }
        },
        selectionTopics() {
            this.$emit('newTopics', this.selectionTopics)
        },
        selectionGenres() {
            this.$emit('newGenres', this.selectionGenres)
        },
        countrySelected() {
            this.$emit('newCountry', this.countrySelected)
        },
        lexilesSelected() {
            this.$emit('newLexiles', this.lexilesSelected)
        },
    },
}
</script>

<style scoped>
.no-uppercase {
    text-transform: none;
}
.search-icon {
    margin-top: 2px;
    margin-left: -117px;
}
</style>
