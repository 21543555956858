<template>
    <v-card elevation="12" class="py-6 px-8">
        <v-row>
            <h2 class="text-h4 font-weight-black px-2">
                {{ $t('library.library') }}
            </h2>
        </v-row>
        <v-row>
            <v-col cols="12" lg="4">
                <SearchFilters
                    class="mt-0 mt-lg-6"
                    v-on:newAge="setAge"
                    v-on:newGenres="setGenres"
                    v-on:newResourceType="setResourceType"
                    v-on:search="setSearch"
                    v-on:newTopics="setTopics"
                    v-on:newCountry="setCountries"
                    v-on:newLexiles="setLexiles"
                />
            </v-col>
            <v-col cols="12" lg="8">
                <Events section="library" class="mt-0 mt-lg-6" />
                <BookCarouselPopUp
                    :titleCarousel="$t('library.title_carousel_1')"
                    :booksList="addedRecentlyBookList"
                />
                <BookList
                    :age="selectedAge"
                    :genres="selectedGenres"
                    :resourceType="selectedResourceType"
                    :search="selectedSearch"
                    :topics="selectedTopics"
                    :countries="selectedCountries"
                    :lexiles="selectedLexiles"
                />
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import * as Sentry from '@sentry/vue'

import BookList from '@/components/library/BookList.vue'
import Events from '@/components/stats/Events.vue'
import SearchFilters from '@/components/library/SearchFilters.vue'
import BookCarouselPopUp from '@/components/library/BookCarouselPopUp.vue'

import bookService from '@/services/book' //eslint-disable-line

export default {
    props: {
        search: { type: String, default: '' },
    },
    components: {
        BookList,
        Events,
        SearchFilters,
        BookCarouselPopUp,
    },
    data() {
        return {
            selectedAge: null,
            selectedGenres: null,
            selectedResourceType: null,
            selectedSearch: '',
            selectedTopics: null,
            selectedCountries: null,
            booksAddedRecentlyList: { code: 0, data: [] },
            selectedLexiles: null,
        }
    },
    created() {
        this.getBooksAddedRecentlyList()
    },
    computed: {
        addedRecentlyBookList() {
            return this.booksAddedRecentlyList
        },
    },
    methods: {
        setAge(age) {
            this.selectedAge = age
        },
        setGenres(genres) {
            this.selectedGenres = genres
        },
        setResourceType(resourceType) {
            this.selectedResourceType = resourceType
        },
        setSearch(search) {
            this.selectedSearch = search
        },
        setTopics(topics) {
            this.selectedTopics = topics
        },
        setCountries(countries) {
            this.selectedCountries = countries
        },
        setLexiles(lexiles) {
            this.selectedLexiles = lexiles
        },
        getBooksAddedRecentlyList() {
            const PAGESIZE = 10

            bookService
                .getBooksAddedRecentlyByQty(PAGESIZE)
                .then((response) => {
                    const booksData = response.content.content

                    if (booksData.length > 0) {
                        this.booksAddedRecentlyList = {
                            code: 1,
                            data: booksData,
                        }
                    } else {
                        this.booksAddedRecentlyList = {
                            code: 2,
                            data: booksData,
                        }
                    }
                })
                .catch((error) => {
                    Sentry.captureException(error)
                    this.booksAddedRecentlyList = {
                        code: 3,
                        error,
                    }
                })
        },
    },
}
</script>
<style scoped>
</style>
